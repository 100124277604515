export const createPunchoutUrls = [
    { env: 'Prod', url: 'https://speeddial.worldpac.com/punchout/v1/create' },
    { env: 'UAT', url: 'https://speeddial-uat.worldpac.com/punchout/v1/create' },
    { env: 'QA', url: 'https://speeddial-qa.worldpac.com/punchout/v1/create' },
    { env: 'Dev', url: 'https://speeddial-dev.worldpac.com/punchout/v1/create' },
    { env: 'localhost', url: 'http://localhost:8080/punchout/v1/create' },
    { env: 'docker', url: 'http://host.docker.internal:8080/punchout/v1/create' },
];
export const apiKeys = {
    us_prd: 'MEG2PQqbctUdNRdNUGLdzySqwxuaZ5D3OybM0Z6m',
    us_qua: 'MXUf5GvHJeQ8XyyPIOzfNgCu3s2UddOGHAASmiWn',
    ca_prd: 'FNWJ5UPJxm7Ri4sV3qSQmAxPkfdAwIeSa16TeRIO',
    ca_qua: 'b8PtcjczJoChtaX5l0FU5NWAXbS3IV3IkAhzh1It',
};
export const companies = [
    { name: 'US', value: 'us-prd', apiKey: apiKeys.us_prd, country: 'US' },
    { name: 'Canada', value: 'ca-prd', apiKey: apiKeys.ca_prd, country: 'CA' },
    { name: 'US QA', value: 'us-qa', apiKey: apiKeys.us_qua, country: 'US' },
    { name: 'Canada QA', value: 'ca-qa', apiKey: apiKeys.ca_qua, country: 'CA' },
];

export const StorageKey = {
    SELECTED_INTEGRATION: 'selectedIntegration',
};

export const APP_NAME = 'SMS Demo2';

export const WEBHOOK_CALLBACK_MODE = {
    GO_TO_WORK_ORDER: 'Return to Work Order',
    STAY_IN_SD: 'Stay in speedDIAL',
    CLOSE_WINDOW: 'Close Window',
};
