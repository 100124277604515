import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { usd } from '../../utils/dom-utils';
import usePersistedState from '../../shared/hooks/user-persisted-state';
import { cloneDeep, has, isArray } from 'lodash';
import { getVehicleDescAndType } from '../utils/vehicle';
import { ProductQuoteSearchForm } from '../shared/product-quote-search-form';
import { NewQuotesDialog } from '../shared/new-quotes-dialog';
import { APP_NAME, StorageKey, WEBHOOK_CALLBACK_MODE } from '../../shared/constants';
import { OrderedPartRow } from './ordered-part-row';
import { PricePartRow } from './price-part-row';
import { useSettingsStore } from '../../config/stores/settings-store';

export const WorkOrder = () => {
    const [customer, setCustomer] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [workOrder, setWorkOrder] = useState(null);
    const [integrations, setIntegrations] = useState([]);
    const [selectedIntegration, setSelectedIntegration] = usePersistedState(StorageKey.SELECTED_INTEGRATION, null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [showQuotes, setShowQuotes] = useState(false);
    const [newQuotes, setNewQuotes] = useState([]);
    const { number } = useParams();
    /** @type {Setting[]} */
    const settings = useSettingsStore(state => state.settings);

    const getWorkOrder = () => {
        axios.get(`/api/work-orders/${number}`).then(response => {
            const workOrderData = response.data.data;

            setWorkOrder(workOrderData);
            setCustomer(workOrderData.customer);
            setVehicle(workOrderData.vehicle);
        });
    };

    // on mount
    useEffect(() => {
        getWorkOrder();
        axios.get('/api/jobs').then(response => {
            const jobsData = response.data.data;

            setJobs(jobsData);
        });
        axios
            .get('/api/integrations')
            .then(response => {
                const integrations = response.data.data;

                setIntegrations(integrations);

                let storedIntegrationIsValid = false;
                if (selectedIntegration) {
                    for (const integration of integrations) {
                        if (integration._id === selectedIntegration._id) {
                            storedIntegrationIsValid = true;
                        }
                    }
                }

                if (storedIntegrationIsValid === false) {
                    setSelectedIntegration(null);
                }
            })
            .catch(e => console.log('Error : ', e));
    }, []);

    const handleSearchProductQuotesClick = async (data, event) => {
        const { searchTerm } = data;

        if (!selectedIntegration) {
            alert('Please select an integration first');
            return;
        }

        if (!searchTerm) {
            alert('You must provide a product ID or search term');
            return;
        }
        let response = await getNewPunchoutData();
        const command = 'GetProductQuotes';

        if (has(response, 'url') && !!response.url) {
            const { url } = response;
            const punchoutUrl = new URL(url.replace('{command}', command));
            const postData = {
                referenceID: workOrder.number,
                punchoutUrl: punchoutUrl.toString(),
                searchTerm,
            };

            try {
                axios
                    .post('/api/search', postData)
                    .then(response => {
                        const { data } = response.data;
                        const { status } = data;
                        if (status.code !== 200) {
                            alert(`Failed to get product quotes: Status code ${status.code}, reason: ${status.message.text}`);
                            console.log(`Failed to get product quotes. Status code ${status.code}, reason: ${status.message.text}`);
                        } else {
                            if (isArray(data.quotes) && data.quotes.length === 0) {
                                alert('No products matched your search results');
                            } else {
                                setShowQuotes(true);
                                setNewQuotes(data.quotes);
                            }
                        }
                    })
                    .catch(reason => {
                        alert(`Failed to get product quotes: ${reason}`);
                    });
            } catch (e) {
                console.log(`Failed to get product quotes: ${e.message}`);
                alert('Failed to get product quotes. See console for more details');
            }
        } else {
            alert('Failed to get a punchout URL');
        }
    };

    const updatePartsFromOrder = async (order, successMessage) => {
        try {
            const clonedParts = cloneDeep(workOrder.parts) || [];
            let workOrderUpdated = false;

            for (const orderLine of order.orderLines) {
                const part = clonedParts.find(p => {
                    return p.productId === orderLine.productID && p.brandId === orderLine.brandID;
                });
                if (part) {
                    workOrderUpdated = true;
                    part.ordered = true;
                    part.meta.orderId = order.orderID;
                    part.meta.orderLine = orderLine;
                }
            }

            let failures = false;
            for (const part of clonedParts) {
                await axios.put(`/api/work-orders/${workOrder.number}/parts/${part._id}`, { part }).catch(reason => {
                    console.error(`Failed to update part: ${part.productId}/${part.brandId}. Reason: ${reason}`);
                    failures = true;
                });
            }
            if (failures) {
                alert('We could not update all parts. See console log for more details');
            }
            getWorkOrder();
        } catch (e) {
            console.error(e);
        }
    };

    const updatePartsFromSubmittedOrder = async (order, successMessage) => {
        try {
            const clonedParts = cloneDeep(workOrder.parts) || [];
            let workOrderUpdated = false;

            for (const orderLine of order.orderLines) {
                const part = clonedParts.find(p => {
                    return p.productId === orderLine.productID && p.brandId === orderLine.brandID;
                });
                if (part) {
                    workOrderUpdated = true;
                    part.submitted = true;
                    part.meta.orderId = order.orderID;
                    part.meta.orderLine = orderLine;
                }
            }

            let failures = false;
            for (const part of clonedParts) {
                await axios.put(`/api/work-orders/${workOrder.number}/parts/${part._id}`, { part }).catch(reason => {
                    console.error(`Failed to update part: ${part.productId}/${part.brandId}. Reason: ${reason}`);
                    failures = true;
                });
            }
            if (failures) {
                alert('We could not update all parts. See console log for more details');
            }
            getWorkOrder();
        } catch (e) {
            console.error(e);
        }
    };

    const handleCreateOrder = async () => {
        console.log('Creating an order from work order data');
        let response = await getNewPunchoutData();
        const command = 'CreateOrder';

        const quotes = [];
        for (const part of workOrder.parts) {
            if (!part.ordered) {
                quotes.push({
                    productID: part.productId,
                    brandID: part.brandId,
                    quantity: part.qty,
                });
            }
        }

        if (quotes.length === 0) {
            alert('No eligible parts to order');
        }

        const postData = {
            quotes,
            referenceID: workOrder.number,
        };

        console.log('postData', postData);

        if (has(response, 'url') && !!response.url) {
            const { url } = response;
            try {
                postData.punchoutUrl = new URL(url.replace('{command}', command));
                axios
                    .post('/api/order', postData)
                    .then(response => {
                        try {
                            const { data } = response.data;
                            const { status, order } = data;
                            if (status.code !== 200) {
                                const errorMessage = `Failed to create order. Status code ${status.code}, reason: ${status.message.text}`;
                                alert(errorMessage);
                                console.log(errorMessage);
                            } else {
                                const successMessage = `Your order was created in speedDIAL. Order ID: ${order.orderID}`;
                                console.log(successMessage);
                                updatePartsFromOrder(data.order, successMessage);
                            }
                        } catch (e) {
                            console.log('caught in the axios.post.then() function', e);
                        }
                    })
                    .catch(reason => {
                        alert(`Failed to create order: ${reason}`);
                    });
            } catch (e) {
                console.log(`(Try/Catch) Failed to create order: ${e.message}`);
                alert('Failed to create order. See console for more details');
            }
        } else {
            alert('Failed to get a punchout URL');
        }
    };

    const handleSubmitOrder = async () => {
        console.log('Submit an order to WP');
        let response = await getNewPunchoutData();
        const orderIds = [];

        // gather all order ID's to submit
        for (const part of workOrder.parts) {
            const orderId = part.meta.orderId;
            if (orderId && !part.submitted && !orderIds.includes(orderId)) {
                orderIds.push(orderId);
            }
        }

        const command = 'SubmitOrder';
        for (const orderId of orderIds) {
            const postData = {
                orderId: orderId,
                referenceId: workOrder.number,
                commentPo: workOrder.number,
            };

            if (has(response, 'url') && !!response.url) {
                const { url } = response;
                try {
                    postData.punchoutUrl = new URL(url.replace('{command}', command));
                    axios
                        .post('/api/order/submit', postData)
                        .then(response => {
                            const { data } = response.data;
                            const { status, order } = data;
                            if (status.code !== 200) {
                                const errorMessage = `Failed to submit order. Status code ${status.code}, reason: ${status.message.text}`;
                                alert(errorMessage);
                                console.log(errorMessage);
                            } else {
                                const successMessage = `Your order was submitted. Order ID: ${order.orderID}`;
                                console.log(successMessage);
                                updatePartsFromSubmittedOrder(data.order, successMessage);
                            }
                        })
                        .catch(reason => {
                            alert(`Failed to submit order: ${reason}`);
                        });
                } catch (e) {
                    console.log(`Failed to submit order: ${e.message}`);
                    alert('Failed to submit order. See console for more details');
                }
            } else {
                alert('Failed to get a punchout URL');
            }
        }
    };

    const getWebhookCallbackModeFromSettings = () => {
        const defaultCallbackMode = WEBHOOK_CALLBACK_MODE.GO_TO_WORK_ORDER;
        const actionAfterExportSettingName = 'actionAfterExport';

        for (const setting of settings) {
            if ((setting.name = actionAfterExportSettingName)) {
                return setting.value;
            }
        }

        return defaultCallbackMode;
    };

    const getNewPunchoutData = async () => {
        let data = null;
        const webhookCallbackMode = getWebhookCallbackModeFromSettings();

        if (selectedIntegration) {
            const requestData = {
                workOrderId: workOrder._id,
                integrationId: selectedIntegration._id,
                webhookCallbackMode,
            };

            await axios
                .post('/api/punchouts', requestData)
                .then(response => {
                    if (has(response, 'data.data')) data = response.data.data;
                })
                .catch(() => {
                    alert('Getting new punchout failed, please see network log');
                });
        } else {
            alert('Please choose an selectedIntegration first');
        }

        console.log('data', data);
        return data;
    };

    const handleGetPartsClick = async () => {
        let response = await getNewPunchoutData();

        if (response === null) return;

        const partNumbers = workOrder.parts.map(part => part.number);
        const command = 'ShowCatalog';

        if (has(response, 'url') && !!response.url) {
            const { url } = response;
            const punchoutUrl = new URL(url.replace('{command}', command));
            const searchParams = punchoutUrl.searchParams;

            if (vehicle.vin) searchParams.append('vin', vehicle.vin);
            if (vehicle.licensePlate) searchParams.append('licensePlate', vehicle.licensePlate);
            if (vehicle.licensePlateState) searchParams.append('licensePlateState', vehicle.licensePlateState);
            if (vehicle.baseVehicleId) searchParams.append('baseVehicleID', vehicle.baseVehicleId);
            if (workOrder.parts.length > 0) searchParams.append('parts', partNumbers.join(','));

            window.open(punchoutUrl.toString(), '_blank');
            console.log(`Window opened to punchout URL: ${punchoutUrl.toString()}`);
        } else {
            alert('Failed to get a punchout URL');
        }
    };

    const handleIntegrationSelect = event => {
        const selectedIntegrationId = event.target.value;
        let integration = null;

        for (let i of integrations) {
            if (i._id === selectedIntegrationId) {
                integration = i;
                break;
            }
        }
        setSelectedIntegration(integration);
    };

    const handleJobSelect = event => {
        const selectedJobName = event.target.value;
        let selectedJob = null;
        for (let job of jobs) {
            if (job.name === selectedJobName) {
                selectedJob = job;
                break;
            }
        }

        setSelectedJob(selectedJob);

        if (selectedJob) {
            let addedParts = [];

            for (const selectedJobPart of selectedJob.parts) {
                if (workOrder.parts) {
                    const matchingParts = workOrder.parts.filter(part => {
                        return part.number === selectedJobPart.number;
                    });

                    if (matchingParts.length === 0) {
                        addedParts.push(selectedJobPart);
                    }
                } else {
                    addedParts = [...selectedJob.parts];
                }
            }

            if (addedParts.length > 0) {
                axios
                    .put(`/api/work-orders/${number}`, { parts: addedParts })
                    .then(() => {
                        getWorkOrder();
                    })
                    .catch(reason => {
                        alert(reason);
                    });
            }
        }
    };

    const handleShowOrder = orderId => async event => {
        console.log(`Show Order with order ID: ${orderId}`);
        let response = await getNewPunchoutData();
        const command = 'ShowOrder';

        if (has(response, 'url') && !!response.url) {
            const { url } = response;
            const punchoutUrl = new URL(url.replace('{command}', command));
            const searchParams = punchoutUrl.searchParams;
            searchParams.append('orderID', orderId);
            searchParams.append('smsVendorName', APP_NAME);

            window.open(punchoutUrl.toString(), '_blank');
            console.log(`Window opened to punchout URL: ${punchoutUrl.toString()}`);
        } else {
            alert('Failed to get a punchout URL');
        }
    };

    const getSelectedIntegrationValue = () => {
        return has(selectedIntegration, '_id') ? selectedIntegration._id : '';
    };

    if (workOrder === null || customer === null || vehicle === null) {
        return (
            <>
                <span className={'placeholder placeholder-lg'}></span>
                <span className={'placeholder col-3'}></span>
                <span className={'placeholder col-3'}></span>
            </>
        );
    }

    const [vehicleDesc, [vehicleTypeLabel, vehicleTypeDesc]] = getVehicleDescAndType(vehicle);

    const hasPartsToOrder = () => {
        if (has(workOrder, 'parts')) {
            for (const part of workOrder.parts) {
                if (!part.ordered) return true;
            }
        }

        return false;
    };

    const hasOrdersToSubmit = () => {
        if (has(workOrder, 'parts')) {
            for (const part of workOrder.parts) {
                if (part.ordered && !part.submitted) return true;
            }
        }

        return false;
    };

    const getOrderLineStatus = order => {
        let lineStatus = has(order, 'orderLine.statusDescription') ? order.orderLine.statusDescription : '';

        if (order.status === '3') {
            lineStatus = order.statusDescription;
        }

        return lineStatus;
    };

    return (
        <div className={'container'}>
            <h1>Work Order: {workOrder.number}</h1>
            <div className={'row'}>
                <div className={'col'}>
                    <div className={'card h-100'}>
                        <div className={'card-body'}>
                            <h5 className={'card-title'}>
                                {customer.firstName} {customer.lastName}
                            </h5>
                            <p className={'card-text customer-details'}>
                                <span>{customer.address}</span>
                                <span>
                                    {customer.city}, {customer.state} {customer.zip}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={'col'}>
                    <div className={'card h-100'}>
                        <div className={'card-body'}>
                            <h5 className={'card-title'}>{vehicleDesc}</h5>
                            <p className={'card-text vehicle-details'}>
                                {vehicleTypeLabel && (
                                    <span className={'work-order-vehicle-attributes'}>
                                        <strong>{vehicleTypeLabel}: </strong> {vehicleTypeDesc}
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <hr />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'row g-3'}>
                            {/*<div className={'col-auto'}>*/}
                            {/*    <select*/}
                            {/*        className={'form-select form-control'}*/}
                            {/*        name="job"*/}
                            {/*        id="job"*/}
                            {/*        onChange={handleJobSelect}*/}
                            {/*        value={(!!selectedJob && selectedJob.name) || ''}*/}
                            {/*    >*/}
                            {/*        <option value="">Add Parts...</option>*/}
                            {/*        {jobs.map(job => {*/}
                            {/*            return (*/}
                            {/*                <option value={job.name} key={job.name}>*/}
                            {/*                    {job.name}*/}
                            {/*                </option>*/}
                            {/*            );*/}
                            {/*        })}*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            <div className={'col-auto'}>
                                <select
                                    className={'form-select form-control'}
                                    name="integration"
                                    id="integration"
                                    onChange={handleIntegrationSelect}
                                    value={getSelectedIntegrationValue()}
                                >
                                    <option value="">Select Integration</option>
                                    {integrations.map(i => {
                                        return (
                                            <option value={i._id} key={i._id}>
                                                {i.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className={'col-auto'}>
                                <button onClick={handleGetPartsClick} className={'btn btn-primary'} disabled={selectedIntegration === null}>
                                    Get Parts
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <button
                                    onClick={handleCreateOrder}
                                    className={'btn btn-primary'}
                                    disabled={selectedIntegration === null || !hasPartsToOrder()}
                                >
                                    Create New Order
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <button
                                    onClick={handleSubmitOrder}
                                    className={'btn btn-primary'}
                                    disabled={selectedIntegration === null || !hasOrdersToSubmit()}
                                >
                                    Submit Order(s)
                                </button>
                            </div>
                            <ProductQuoteSearchForm onSearch={handleSearchProductQuotesClick} disabled={selectedIntegration === null} />
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <hr />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>Part Description</th>
                                    <th>Part Number</th>
                                    <th>Qty</th>
                                    <th>Unit Price</th>
                                    <th>Total Price</th>
                                    <th>Order ID</th>
                                    <th>Comment</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {workOrder.parts.map(part => {
                                    if (part.ordered) {
                                        return <OrderedPartRow part={part} onOrderLinkClick={handleShowOrder} key={part._id} />;
                                    } else {
                                        return <PricePartRow part={part} key={part._id} />;
                                    }
                                })}
                                {workOrder.parts.length === 0 && (
                                    <tr>
                                        <td colSpan={8} style={{ textAlign: 'center' }}>
                                            <span className={'no-records-message'}>No Parts Added</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            {workOrder.parts.length > 0 && (
                                <tfoot>
                                    <tr style={{ fontWeight: 'bold', borderTopWidth: '4px', borderBottomWidth: '4px' }}>
                                        <td colSpan={4} style={{ textAlign: 'right' }}>
                                            Total:
                                        </td>
                                        <td>{usd(workOrder.partsTotal)}</td>
                                    </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                </div>
                {workOrder.orderDetails && (
                    <>
                        <h2>Order Details Transferred from speedDIAL</h2>
                        <div className={'row'}>
                            <div className={'col'}>
                                <pre>{JSON.stringify(workOrder.orderDetails, null, '    ')}</pre>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <NewQuotesDialog show={showQuotes} quotes={newQuotes} onClose={() => setShowQuotes(false)} />
        </div>
    );
};
