import SettingRadio from './setting-radio';
import SettingInput from './setting-input';
import SettingCheckbox from './setting-checkbox';

const settingsFactory = type => {
    const components = {
        radio: SettingRadio,
        input: SettingInput,
        checkbox: SettingCheckbox,
    };

    return components[type] || null;
};

export default settingsFactory;
