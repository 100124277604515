/** @typedef {import('../../types').Setting} FrontendSetting */

import { create } from 'zustand/react';
import axios from 'axios';

export const useSettingsStore = create((set, get) => ({
    /** @type FrontendSetting[] */
    settings: [],
    /** @type boolean */
    loading: false,
    /** @type string */
    error: null,
    /**
     * Get settings from storage
     * @returns {Promise<void>}
     */
    get: async () => {
        set({ loading: true, error: null });
        const response = await axios.get('/api/settings').catch(err => {
            set({ loading: false, error: err.message });
        });
        const settings = response.data.data;

        set({ settings, loading: false, error: null });
    },
    /**
     * Save settings to storage
     * @param newSettings
     * @returns {Promise<void>}
     */
    save: async newSettings => {
        const response = await axios.put('/api/settings', {
            settings: newSettings,
        });

        set({ settings: newSettings, loading: false, error: null });
    },
}));
