import React from 'react';

const SettingRadio = ({ setting, onChange }) => {
    return (
        <div className="setting-radio">
            <label className={'main-label'}>{setting.label}</label>
            {setting.options.map(option => {
                const id = `${setting.name}-${option}`;
                const checkedSetting = setting.value || setting.defaultValue;

                return (
                    <div key={option}>
                        <input
                            type="radio"
                            id={id}
                            name={setting.name}
                            value={option}
                            checked={checkedSetting === option} // Determine if this radio is checked
                            onChange={() => onChange(setting.name, option)} // Update Zustand store
                            className="setting-radio-option"
                        />
                        <label htmlFor={id} className={'setting-radio-option-label'}>
                            {option}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};
export default SettingRadio;
