import React from 'react';

const SettingInput = ({ setting, value, onChange }) => (
    <div className="setting-option setting-input">
        <label className={'setting-label'}>{setting.label}</label>
        <input type="text" name={setting.name} value={value || ''} onChange={e => onChange(setting.name, e.target.value)} />
    </div>
);

export default SettingInput;
