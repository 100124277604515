import React from 'react';
import './index.css';
import { App } from './app';
import axios from 'axios';

import './custom.scss';
import { createRoot } from 'react-dom/client';

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            window.location = '/login';
            return Promise.reject('Unauthorized');
        }
        // Handle other errors here
        return Promise.reject(error);
    },
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
