import React, { useEffect, useState } from 'react';
import axios from 'axios';
import IntegrationList from '../integration/integration-list';
import { AddEditIntegrationModal } from '../integration/add-edit-integration-modal';
import { Button } from 'react-bootstrap';
import { useSettingsStore } from '../../config/stores/settings-store';
import settingsFactory from './settings-factory';
import { cloneDeep } from 'lodash';

export const Settings = () => {
    const [integrations, setIntegrations] = useState([]);
    const [showAddEditForm, setShowAddEditForm] = useState(false);
    const settings = useSettingsStore(state => state.settings);
    const saveSettings = useSettingsStore(state => state.save);
    const [localSettings, setLocalSettings] = useState([]);
    const [settingsAreDirty, setSettingsAreDirty] = useState(false);

    useEffect(() => {
        getIntegrations();
        setLocalSettings(settings);
    }, []);

    useEffect(() => {
        setLocalSettings(settings);
    }, [settings]);

    const getIntegrations = () => {
        axios
            .get('/api/integrations')
            .then(response => {
                setIntegrations(response.data.data);
            })
            .catch(e => console.log('Error : ', e));
    };

    const handleAddIntegration = formData => {
        axios
            .post('/api/integrations', formData)
            .then(() => {
                getIntegrations();
            })
            .catch(({ message }) => {
                console.error(message.message);
            })
            .finally(() => {
                handleCloseAddEditForm();
            });
    };

    const handleDeleteIntegration = integration => () => {
        axios
            // TODO: change this API to use the ID of the integration, instead of the name
            .delete(`/api/integrations/${integration.name}`)
            .then(() => {
                getIntegrations();
            })
            .catch(({ message }) => {
                console.error(message.message);
            });
    };

    const handleShowAddEditForm = () => {
        setShowAddEditForm(true);
    };

    const handleCloseAddEditForm = () => {
        setShowAddEditForm(false);
    };

    const handleSettingsChange = (settingName, option) => {
        const newSettings = cloneDeep(localSettings);

        for (const setting of newSettings) {
            if (settingName === setting.name) {
                setting.value = option;
            }
        }
        setLocalSettings(newSettings);
        setSettingsAreDirty(true);
    };

    const handleSaveSettings = () => {
        saveSettings(localSettings).catch(e => {
            alert(`We encountered an error updating settings. Message: ${e.message}`);
            console.log('Error : ', e);
        });
        setSettingsAreDirty(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <h2>Integrations</h2>
                <Button className={''} variant={'success'} onClick={handleShowAddEditForm} size={'sm'}>
                    + Add
                </Button>
            </div>
            <div className="panel">
                <IntegrationList integrations={integrations} onDelete={handleDeleteIntegration} />
                <AddEditIntegrationModal show={showAddEditForm} onSubmit={handleAddIntegration} onClose={handleCloseAddEditForm} />
            </div>
            <div className="panel">
                <h2>Settings</h2>
                {localSettings.map(setting => {
                    const Component = settingsFactory(setting.type);

                    return Component && <Component key={setting.name} setting={setting} onChange={handleSettingsChange} />;
                })}
            </div>
            <div className="panel">
                <Button type={'submit'} variant={'primary'} onClick={handleSaveSettings} disabled={!settingsAreDirty}>
                    Save Settings
                </Button>
            </div>
        </>
    );
};
