import React from 'react';

const SettingCheckbox = ({ setting, value, onChange }) => (
    <div className={'setting-option setting-checkbox'}>
        <label className={'setting-label'}>{setting.label}</label>
        {setting.options.map(option => (
            <div key={option}>
                <input
                    type="checkbox"
                    name={setting.name}
                    value={option}
                    checked={value?.includes(option)}
                    onChange={e => {
                        const newValue = value || [];
                        if (e.target.checked) {
                            onChange(setting.name, [...newValue, option]);
                        } else {
                            onChange(
                                setting.name,
                                newValue.filter(item => item !== option),
                            );
                        }
                    }}
                />
                <label>{option}</label>
            </div>
        ))}
    </div>
);

export default SettingCheckbox;
